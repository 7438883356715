@import '../../styles/customMediaQueries.css';

.root {
  background: var(--colorLightBeige);
  margin: 0 auto;
  max-width: var(--contentMaxWidthLarge);
  padding: 0 0 6px;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  align-items: center;
  background-color: var(--colorForeground);
  border-radius: var(--borderRadiusLarge);
  color: var(--colorSuperLightBeige);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  justify-content: center;
  line-height: 19.5px;
  margin: 0 auto;
  max-width: var(--contentMaxWidthLarge);
  width: 100%;
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.actionBarForProductLayout {
  border-radius: var(--borderRadiusLarge);
  margin: 6px;
  width: calc(100% - 12px);

  @media (--viewportLarge) {
    margin: 6px auto 0;
    width: 100%;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.alertIcon {
  fill: var(--colorAlertRed);
  stroke: var(--colorWhite);
}

.alertIcon circle {
  stroke: none;
}

.ownListingText {
  font-size: 14px;
  margin: 18px 0 18px 12px;
  padding: 0;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  color: var(--colorSuperLightBeige);
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 12px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: 0px 7px 0 0;

  fill: none;
  height: 16px;
  width: 16px;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }
}

.mainColumnForProductLayout {
  @media (--viewportLarge) {
    margin: 0 auto;
    max-width: var(--contentMaxWidthLarge);
    padding: 0 6px;
  }
}

.orderColumnForProductLayout {
  @media (--viewportLarge) {
    display: block;
    flex-basis: 312px;
    flex-shrink: 0;
    grid-area: order;
    margin-left: 12px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.reviewRating {
  margin-right: 6px;
}

.reviewContainer {
  align-items: center;
  background: var(--colorGreenDark);
  border-radius: var(--borderRadiusLarge);
  color: var(--colorWhite);
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: center;
  margin: 18px 18px 42px 18px;
  padding: 6px;

  @media (--viewportLarge) {
    margin: 18px 18px 42px 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.reviewDivider {
  border-left: 2px solid var(--marketplaceColor);
  display: block;
  height: 100%;
  margin: 0 48px;
}

.reviewCountSummary {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.reviewCount {
  font-size: 20px;
}

.orderColumnForHeroLayout {
  display: block;
  margin-top: 80px;
  border-left: 1px solid var(--colorGrey100);
  padding-left: 60px;
  flex-basis: 312px;
  flex-shrink: 0;
}

.listingContainer {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'overview order'
      'details order';
    margin-top: 6px;
  }
}

.overview {
  background: var(--colorWhite);
  grid-area: overview;
  padding-top: 6px;

  @media (--viewportLarge) {
    border-radius: 1rem 1rem 0 0;
    margin: 0;
  }
}

.listingDetails,
.listingDetailsOverview {
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusLarge);
  margin: 6px;
  padding: 0 0 54px 0;
  width: calc(100% - 12px);

  @media (--viewportLarge) {
    border-radius: 0 0 1rem 1rem;
    grid-area: details;
    margin: 0;
    padding: 18px 48px 18px;
    width: 100%;
  }
}

.listingDetails h1,
.listingDetailsOverview h1 {
  font-size: 25px;
  font-weight: var(--fontWeightRegular);
  line-height: 35px;
  margin: 0 18px 0;

  @media (--viewportLarge) {
    margin: 0;
  }
}

.listingDetailsOverview {
  @media (--viewportLarge) {
    border-radius: 1rem 1rem 0 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  background: var(--colorSecondaryDark);
  border-radius: var(--borderRadiusLarge);
  margin: 6px;

  @media (--viewportLarge) {
    display: block;
    margin: 0;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  /* @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  } */
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;

  margin-top: 12px;
  margin-left: 0;
  margin-bottom: 12px;
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportLarge) {
    max-width: 66.666667%;
    padding: 8px 0;
  }
}

.details,
.detailsLarge {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
  padding-bottom: 1px;
  margin: 0;
  white-space: pre-line;
}

.detailsLarge {
  font-size: 18px;
  line-height: 28px;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 320px */
  display: flex;
  max-width: 320px;
  max-height: 320px;
  background-color: #eee;
  padding: 0;
  border: 0;
  border-radius: var(--borderRadiusLarge);
  cursor: pointer;

  @media (--viewportLarge) {
    max-width: 100%;
    width: 100%;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.mobileIconsContainer {
  position: relative;
}

.mobileIcons {
  display: block;
  position: absolute;
  right: 18px;
  top: 18px;

  @media (--viewportLarge) {
    display: none;
  }
}

.mobileIcons svg {
  background-color: var(--colorWhite);
  border-radius: 50%;
  fill: none;
  height: 20px;
  margin-left: 9px;
  padding: 12px;
  width: 20px;

  &:hover {
    background-color: var(--colorLightBeige);
    cursor: pointer;
  }
}

.productGallery {
  margin: 0;
}

.listingHeadingDesktop {
  align-items: center;
  background-color: var(--colorDarkBeige);
  border-radius: var(--borderRadiusLarge);
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-between;
  margin: 6px 0;
  padding: 0px 36px 0;
}

.headerLeft {
  align-items: center;
  display: flex;
}

.headerLeft h1 {
  font-size: 25px;
  font-weight: var(--fontWeightRegular);
  margin: 0 0 0 12px;

  @media (--viewportLarge) {
    display: none;
  }
}

.headerCenter h1 {
  display: none;
  font-size: 35px;
  font-weight: var(--fontWeightRegular);
  line-height: 35px;
  margin: 18px 0 6px;
  text-align: center;

  @media (--viewportLarge) {
    display: block;
  }
}

.headerCenter p {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;

  @media (--viewportLarge) {
    display: block;
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 12px 0;
    text-align: center;
  }
}

.headerRight {
  display: none;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 16px;

  @media (--viewportLarge) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}

.headerRight span {
  vertical-align: middle;
}

.headerRight button {
  &:hover {
    cursor: pointer;
  }

  &:hover span {
    color: var(--colorDarkGrey);
  }

  &:hover svg {
    background-color: var(--colorLightBeige);
  }
}

.headerRight svg {
  background-color: var(--colorWhite);
  border-radius: 50%;
  fill: none;
  height: 24px;
  margin: 0 12px 0 18px;
  padding: 12px;
  stroke: var(--colorForeground);
  width: 24px;
}

.editListingButton {
  all: unset;
  color: var(--colorForeground);
  display: inline-block;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover span {
    color: var(--colorDarkGrey);
  }

  &:hover svg {
    background-color: var(--colorLightBeige);
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.listingHeading {
  background-color: var(--colorDarkBeige);
  border-radius: var(--borderRadiusLarge);
  margin: 0 6px 6px;
  padding: 24px 0;
  width: calc(100% - 12px);

  @media (--viewportLarge) {
    display: none;
  }
}

.listingHeadingDesktop {
  display: none;

  @media (--viewportLarge) {
    display: grid;
  }
}

.listingHeading h1 {
  font-size: 25px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  margin: 0 24px;
}

.listingHeading p {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin: 0 24px;
}

.listingHeading a {
  color: var(--colorForeground);
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.item,
.virtualLink {
  display: flex;
  align-items: center;
  padding: 9px 0;
}

.virtualLink {
  margin-top: 18px;
  padding: 0;
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.iconWrapper svg {
  fill: none;
}

.labelWrapper {
  display: inline-block;
}

.sectionReviews {
  background: var(--colorWhite);
  border-radius: var(--borderRadiusLarge);
  margin: 6px;
  padding: 48px 0 24px 0;

  @media (--viewportLarge) {
    margin: 6px 0;
  }
}

.sectionReviewsTitle {
  font-size: 25px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  margin: 0 0 0 24px;

  @media (--viewportLarge) {
    margin: 0 0 0 48px;
  }
}

.sectionReviewsSubtitle {
  color: var(--colorDarkBeige);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin: 0 0 42px 24px;

  @media (--viewportLarge) {
    margin: 0 0 42px 48px;
  }
}

.sectionReviewsContainer {
  display: block;
  grid-template-columns: 1fr 1fr;

  @media (--viewportLarge) {
    display: grid;
  }
}

.reviewListingLink {
  composes: buttonPrimaryInline from global;

  padding: 12px 16px 12px 16px;
  margin: 12px 0 0 12px;

  float: left;
  flex-shrink: 0;
}

.completeLink {
  composes: buttonPrimaryInline from global;

  padding: 12px 16px 12px 16px;
  margin: 12px 24px 12px 12px;

  float: left;
  flex-shrink: 0;
}

.completeLink svg {
  margin-left: 6px;
}

.backLink {
  composes: buttonSecondaryInline from global;

  padding: 12px 16px 12px 16px;
  margin: 12px 0;

  float: left;
  flex-shrink: 0;
}

.viewListingIcon {
  /* float: right; */
  stroke: white;
  fill: none;
  height: 16px;
  width: 16px;
  margin-left: 6px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--colorForeground);
  stroke: var(--colorForeground);
  margin-right: 6px;
  height: 12px;
  width: 12px;

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.completeArrowIcon {
  /* Color for svg icons */
  fill: var(--colorWhite);
  stroke: var(--colorWhite);
  margin-right: 6px;
  height: 12px;
  width: 12px;

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.stickyButtons {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0 24px 0 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  align-items: center;
  display: flex;
  justify-content: flex-end;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 0 60px 0 60px;
  }
}

.shareNetwork {
  align-items: center;
  background: var(--colorLightBeige);
  border-radius: 50%;
  color: var(--colorForeground);
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  margin-right: 12px;
  width: 48px;

  &:hover:not(:active) {
    background: var(--colorDarkBeige);
  }
}

.shareNetworkContainer {
  margin-top: 20px;
}

.shareIcon {
  fill: none;
  height: 24px;
  stroke: var(--colorForeground);
  width: 24px;
}

.shareIconCustom {
  fill: var(--colorForeground);
  height: 36px;
  stroke: var(--colorForeground);
  width: 36px;
}

.shareCopyToClipboardContainer svg {
  &:hover {
    cursor: pointer;
    stroke: var(--colorDarkBeige);
  }
}

.reviewModalScrollLayer {
  flex-grow: 1;
  display: flex;

  /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
  background-color: var(--colorWhite);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }
}

.reviewModalContainer {
  background-color: var(--colorSuperLightBeige);
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-self: flex-end;
  min-height: 100vh;
  position: relative;

  @media (--viewportMedium) {
    height: 100vh;
    width: 585px;
  }
}

.reviewModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}

.reviewModalTitle {
  color: var(--colorWhite);
  font-size: 42px;
  font-weight: var(--fontWeightRegular);
  line-height: 48px;
  margin: 60px 72px 120px 30px;
  max-width: 400px;
  position: absolute;
  z-index: 1;

  @media (--viewportMedium) {
    font-size: 58px;
    margin: 120px 150px 240px 72px;
    line-height: 60px;
  }
}

.reviewModalImageContainer {
  position: relative;
}

.reviewModalImageOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 0;
}

.reviewModalSubtitle {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 30px;
  margin: 36px 30px 18px;

  @media (--viewportMedium) {
    font-size: 25px;
    line-height: 35px;
    margin: 36px 60px 18px;
  }
}

.reviewModalText {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  margin: 0 30px 36px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
    margin: 0 60px 36px;
  }
}

.modalButtonBar {
  background: var(--colorSuperLightBeige);
  border-top: 1px solid var(--colorDarkBeige);
  margin-bottom: 0;
  padding-left: 30px;

  @media (--viewportMedium) {
    padding-left: 60px;
  }
}

.providerSocialShareWrapper {
  margin-top: 32px;
}
